<template>
  <div id="photosDistributed">
    <div>
      <button
        type="button"
        style="width: 150px; margin-right: 0px; margin-bottom:20px; font-size: 14px; border-radius: 4px 0 0 4px"
        @click="btnState = 1"
        :class="[
          'btn',
          'btn-lg',
          'btn-secondary',
          btnState == 1 ? 'btn-primary' : '',
        ]"
      >
        员工考勤
      </button>
      <button
        type="button"
        style="width: 150px; margin-right: 0px; margin-bottom:20px; font-size: 14px; border-radius: 0 4px 4px 0"
        @click="btnState = 2"
        :class="[
          'btn',
          'btn-lg',
          'btn-secondary',
          btnState == 2 ? 'btn-primary' : '',
        ]"
      >
        用户通行
      </button>
    </div>
    <template
      v-if="btnState == 1"
    >
      <div class="filter-panel">
        <span>将本园区员工照片下发到考勤面板，请选择考勤面板</span>
        <div
            style="display: inline-block; vertical-align: middle; position: relative; margin-right: 20px;"
            @click.stop="employeeTreePanelVisible = !employeeTreePanelVisible"
          >
          <div class="tree-wrap" style="--height: 30px; --icon-max-width: 36px">
            <CSSelect
              height="30px"
              style="width: 155px;"
              iWidth="26px"
            >
              <div
                v-if="attendancePanelNames.length > 0"
                style="width: 119px; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
              >
                <span
                  v-for="(panelName, roomIndex) in attendancePanelNames"
                  :key="roomIndex"
                  style="font-size: 14px; margin-left: 10px"
                  >
                  {{panelName}}
                </span>
              </div>
              <div
                v-else
                style="font-size: 14px; color: #999; margin-left: 10px"
              >
                请选择
              </div>
            </CSSelect>
            <TreePanel
              v-show="employeeTreePanelVisible"
              @loadMore="getAttendancePanel()"
              width="300px"
            >
              <CSTree
                :tree="attendancePanelList"
                @change="employeeOnChangeRooms"
                textName="deviceNoAddId"
              >
              </CSTree>
            </TreePanel>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary queryBtn"
          style="width: 50px; height: 30px; padding: 0;"
          @click="tipsVisible = true"
        >下发</button>
      </div>
    </template>
    <template
      v-else
    >
      <div class="filter-panel">
        <span>将本园区用户照片、门卡下发到通行面板，请选择闸机/楼层门禁面板</span>
        <div
            style="display: inline-block; vertical-align: middle; position: relative; margin-right: 20px;"
            @click.stop="userTreePanelVisible = !userTreePanelVisible"
          >
          <div class="tree-wrap" style="--height: 30px; --icon-max-width: 36px">
            <CSSelect
              height="30px"
              style="width: 155px;"
              iWidth="26px"
            >
              <div
                v-if="gateAddFloorPanelsNames.length > 0"
                style="width: 119px; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
              >
                <span
                  v-for="(panelName, roomIndex) in gateAddFloorPanelsNames"
                  :key="roomIndex"
                  style="font-size: 14px; margin-left: 10px"
                  >
                  {{panelName}}
                </span>
              </div>
              <div
                v-else
                style="font-size: 14px; color: #999; margin-left: 10px"
              >
                请选择
              </div>
            </CSSelect>
            <TreePanel
              v-show="userTreePanelVisible"
              @loadMore="getGateFloorList()"
              width="300px"
            >
              <CSTree
                :tree="gateAddFloorPanelsList"
                @change="userOnChangeRooms"
                textName="deviceNoAddId"
              >
              </CSTree>
            </TreePanel>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary queryBtn"
          style="width: 50px; height: 30px; padding: 0;"
          @click="tipsVisible = true"
        >下发</button>
        <div style="padding-bottom: 20px">
          <span>
                      清除离职员工照片
          </span>
          <button
                  type="button"
                  class="btn btn-primary queryBtn"
                  style="width: 50px; height: 30px; padding: 0;margin: 0 0 0 15px"
                  @click="removeStaffPicture"
          >清除</button>
        </div>

      </div>
    </template>
    <CSDialog
      dialogTitle="提示"
      :dialogVisible="tipsVisible"
      @onConfirm="employeeAddUserPhotoDistributionBtn"
      dialogWidth="460px"
      @onClose="tipsVisible = false"
      dialogConfirmBtnText="确定"
    >
      <div
        slot="dialog-content"
        style="padding: 30px; font-size: 24px; text-align: center;"
      >
        确定下发吗？
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";

import {
  queryDevice, sendCardEventByDeviceIdUrl,
  syncFaces,removeStaffPictureUrl,
} from "@/requestUrl"

  export default {
    name: "photosDistributed",
    components: {
      CSSelect,
      CSDialog,
      TreePanel,
      CSTree
    },
    data() {
      return {
        // 切换按钮状态
        btnState: 1,
        // 员工列表树显隐
        employeeTreePanelVisible: false,
        // 用户列表树显隐
        userTreePanelVisible: false,
        // 提示对话框显隐
        tipsVisible: false,
        // 初始查询考勤面板数据
        queryAttendancePanel: {
          regionCode: this.$vc.getCurrentRegion().code,              //园区编码，必填
          keyword: "",                  //查询关键词，选填
          type: "",                       //面板类型，选填
          buildingCode: "",             //楼栋编码，选填
          pageNo: 1,                    // 页码，选填
          pageSize: 10,                 // 每页条目数量，选填
        },
        // 考勤面板列表
        attendancePanelList: [],
        // 选中的考勤面板名称
        attendancePanelNames: [],
        // 选中的考勤面板ID
        attendancePanelIds: [],
        // 进闸机列表
        enterGatePanelList: [],
        // 出闸机列表
        outGatePanelList: [],
        // 楼层门禁面板
        floorPanelLost: [],
        // 闸机面板与楼层门禁面板
        gateAddFloorPanelsList: [],
        // 选中的闸机与楼层门禁名称
        gateAddFloorPanelsNames: [],
        // 选中的闸机与楼层门禁ID
        gateAddFloorPanelIds: [],
        // 考勤页数
        attendancePageNo: 0,
        // 进闸机页数
        enterGatePageNo: 0,
        // 出闸机页数
        outGatePageNo: 0,
        // 楼层门禁页数
        floorPageNo: 0,
      }
    },
    created() {
      this.getAttendancePanel();
      this.getGateFloorList();
      document.body.addEventListener('click', event => {
          this.employeeTreePanelVisible = false;
          this.userTreePanelVisible = false;
      });
    },
    methods: {
      // 员工页面tree的change事件
      employeeOnChangeRooms(checkBoxInfo) {
        const { key, source } = checkBoxInfo,
              checkbox = source[key];
        if (!checkbox?.children) {
          const index = this.attendancePanelIds.indexOf(checkbox.id);
          if (index > -1) {
            checkbox.checked = false;
            this.attendancePanelNames.splice(index, 1);
            this.attendancePanelIds.splice(index, 1);
          } else {
            checkbox.checked = true;
            this.attendancePanelIds.push(checkbox.id);
            this.attendancePanelNames.push(checkbox.deviceNoAddId);
          }
        } else {
          this.changeChildrenCheckState(
            checkbox,
            {checkedIds: this.attendancePanelIds, attendancePanelNames},
            !checkbox.checked
          );
        }
      },
      // 用户页面tree的change事件
      userOnChangeRooms(checkBoxInfo) {
        const { key, source } = checkBoxInfo,
              checkbox = source[key];
        if (!checkbox?.children) {
          const index = this.gateAddFloorPanelIds.indexOf(checkbox.id);
          if (index > -1) {
            checkbox.checked = false;
            this.gateAddFloorPanelsNames.splice(index, 1);
            this.gateAddFloorPanelIds.splice(index, 1);
          } else {
            checkbox.checked = true;
            this.gateAddFloorPanelIds.push(checkbox.id);
            this.gateAddFloorPanelsNames.push(checkbox.deviceNoAddId);
          }
        } else {
          this.changeChildrenCheckState(
            checkbox,
            {checkedIds: this.gateAddFloorPanelIds, gateAddFloorPanelsNames},
            !checkbox.checked
          );
        }
      },
      // 初始查询考勤面板
      getAttendancePanel() {
        this.$fly
            .post(queryDevice, {
              ...this.queryAttendancePanel,
              type: 5,
              pageNo: ++this.attendancePageNo,
              pageSize: 20,
            })
            .then(res => {
              if (res.code != 0) {
                return;
              }
              this.attendancePanelList = res.data.datas.map(item => {
                item.deviceNoAddId = item.deviceNo + '-' + item.id
                return item
              });
            })
      },
      // 获取进闸机面板
      getEnterGate() {
        this.$fly
            .post(queryDevice, {
              ...this.queryAttendancePanel,
              type: 1,
              pageNo: ++this.enterGatePageNo,
            })
            .then(res => {
              if (res.code != 0) {
                return;
              }
              this.enterGatePanelList = res.data.datas.map(item => {
                item.deviceNoAddId = item.deviceNo + '-' + item.id
                return item
              });
            })
      },
      // 获取出闸机面板
      getOutGate() {
        this.$fly
            .post(queryDevice, {
              ...this.queryAttendancePanel,
              type: 2,
              pageNo: ++this.outGatePageNo,
            })
            .then(res => {
              if (res.code != 0) {
                return;
              }
              this.outGatePanelList = res.data.datas.map(item => {
                item.deviceNoAddId = item.deviceNo + '-' + item.id
                return item
              });
            })
      },
      // 获取楼层门禁面板
      getFloor() {
        this.$fly
            .post(queryDevice, {
              ...this.queryAttendancePanel,
              type: 6,
              pageNo: ++this.floorPageNo,
            })
            .then(res => {
              if (res.code != 0) {
                return;
              }
              this.floorPanelLost = res.data.datas.map(item => {
                item.deviceNoAddId = item.deviceNo + '-' + item.id
                return item
              });
              setTimeout(() => {
                this.mergeGateFloor();
              }, 100);
            })
      },
      // 将楼层门禁与闸机数组合并
      mergeGateFloor() {
        let arr = [...this.enterGatePanelList, ...this.outGatePanelList, ...this.floorPanelLost];
        this.gateAddFloorPanelsList.push(...arr);
      },
      // 获取闸机与楼层门禁面板
      getGateFloorList() {
        this.getEnterGate();
        this.getOutGate();
        this.getFloor();
      },
      // 用户照片下发按钮
      employeeAddUserPhotoDistributionBtn() {
        let deviceIds = [];
        let promiseAll = [];
        if (this.btnState == 1) {
          deviceIds = this.attendancePanelIds;
        } else {
          deviceIds = this.gateAddFloorPanelIds;
          promiseAll.push(this.$fly.post(sendCardEventByDeviceIdUrl, {deviceIds}));
        }
        if(deviceIds.length > 1){
          this.$vc.toast("下发失败，只能选择一个面板下发");
          this.tipsVisible = false;
          return;
        }

        promiseAll.unshift(this.$fly.post(syncFaces, {deviceIds}));
        Promise.all(promiseAll)
        .then(([res, cardRes]) => {
          if (res.code != 0 || cardRes.code !== 0) {
            // this.$vc.toast("下发照片失败");
            return;
          }
          this.$vc.toast("照片、门卡下发成功");
        })

        this.tipsVisible = false;
        this.employeeTreePanelVisible = false;
        this.userTreePanelVisible = false;
        this.attendancePanelNames = [];
        this.attendancePanelIds = [];
        this.gateAddFloorPanelsNames = [];
        this.gateAddFloorPanelIds = [];
        this.attendancePanelList.forEach(item => {
          this.$set(item, "checked", false);
        })
        this.gateAddFloorPanelsList.forEach(item => {
          this.$set(item, "checked", false);
        })
      },
      //清空离职员工照片按钮
      removeStaffPicture(){
        this.$fly.get(removeStaffPictureUrl)
                .then(res => {
                  if (res.code != 0) {
                    return;
                  }
                  this.$vc.toast("清除成功");
                })
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
